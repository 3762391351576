import { ResourceType } from "../../types/resource"

export const typeMap: Record<string, ResourceType> = {
  ContentfulArticle: "article",
  ContentfulDevotional: "devotional",
  ContentfulMediaResource: "mediaResource",
  ContentfulPost: "post",
  ContentfulSeries: "series",
  ContentfulSermon: "sermon",
  ContentfulVideo: "video",
  ContentfulCollection: "collection"
}
