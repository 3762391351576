import { AboutStoriesFragment } from "../../../../graphql/types"
import { mapYoutubeId } from "../../../core/mappers/map-youtube-id"
import { Stories } from "../about-types"

export const mapAboutStoriesToView = (data: AboutStoriesFragment): Stories => {
  return {
    title: data.storiesTitle || "",
    subtitle: data.storiesSubtitle?.childMarkdownRemark?.rawMarkdownBody || "",
    list: [
      {
        title: data.story1Title || "",
        body:
          data.story1Description?.childMarkdownRemark?.rawMarkdownBody || "",
        youtubeId: mapYoutubeId(data.story1YouTubeVideo) || ""
      },
      {
        title: data.story2Title || "",
        body:
          data.story2Description?.childMarkdownRemark?.rawMarkdownBody || "",
        youtubeId: mapYoutubeId(data.story2YouTubeVideo) || ""
      }
    ]
  }
}
