import {
  AboutFounderFragment,
  VariantWithProductFragment
} from "../../../../graphql/types"
import { mapVariantDetailsToView } from "../../store/mappers/product-details-mapper"
import { Founder } from "../about-types"
import { mapSourceFeaturedItemsToView } from "@features/home-page/mappers/featured-items-mapper"

export const mapAboutFounderToView = (data: AboutFounderFragment): Founder => {
  return {
    name:
      data.founderContentBlock?.title?.childMarkdownRemark?.rawMarkdownBody ||
      "",
    image: data.founderContentBlock?.image?.fluid?.src || "",
    info:
      data.founderContentBlock?.description?.childMarkdownRemark
        ?.rawMarkdownBody || "",
    url: data.founderContentBlock?.urlLink || "",
    products: data.founderFeaturedWorks
      ? data.founderFeaturedWorks.map((p) =>
          mapVariantDetailsToView(p as VariantWithProductFragment)
        )
      : undefined,
    resources: mapSourceFeaturedItemsToView(data.founderResources) || null
  }
}
