import { AboutDonateFragment } from "../../../../graphql/types"
import { Donate } from "../about-types"

export const mapAboutDonateToView = (data: AboutDonateFragment): Donate => {
  return {
    subtitle: data.donateBlock?.title?.childMarkdownRemark?.html || "",
    title: data.donateBlock?.description?.childMarkdownRemark?.html || "",
    image: data.donateBlock?.image?.fluid?.src || "",
    url: data.donateBlock?.urlLink || "",
    link: {
      url: data.donateLinkUrl || "",
      text: data.donateLinkText || ""
    }
  }
}
