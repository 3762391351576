import { AboutWhatWeBelieveFragment } from "../../../../graphql/types"
import { WhatWeBelieve } from "../about-types"

export const mapAboutWhoWeBelieveToView = (
  data: AboutWhatWeBelieveFragment
): WhatWeBelieve => {
  return {
    subtitle: data.whatWeBelieveSubtitle || "",
    title:
      data.whatWeBelieveDescription?.childMarkdownRemark?.rawMarkdownBody || "",
    list: (data.whatWeBelieveContentBlocks || []).map((block) => ({
      title: block?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      body: block?.description?.childMarkdownRemark?.rawMarkdownBody || "",
      url: block?.urlLink || ""
    }))
  }
}
