import { AboutPartnersFragment } from "../../../../graphql/types"
import { Partners } from "../about-types"

export const mapAboutPartnersToView = (
  data: AboutPartnersFragment
): Partners => {
  return {
    title: data.partnerWithUsTitle || "",
    subtitle: data.partnerWithUsSubtitle || "",
    image: data.partnerWithUsImage?.fluid?.src || "",
    button: {
      url: data.partnerWithUsButtonUrl || "",
      text: data.partnerWithUsButtonText || ""
    },
    link: {
      url: data.partnerWithUsLinkUrl || "",
      text: data.partnerWithUsLinkText || ""
    }
  }
}
