import React, { FunctionComponent } from "react"
import { TeachingFellows } from "../../about-types"
import { TeacherView } from "./teacher"
import styles from "./teaching-fellowship.module.scss"
import { Button } from "@components/button/button"
import { Markdown } from "@components/markdown/markdown"

export const TeachingFellowship: FunctionComponent<TeachingFellows> = ({
  title,
  description,
  teachers,
  button
}) => (
  <div className={styles.teachingFellowship}>
    <div className={styles.content}>
      <div className={styles.text}>
        <h3 className={styles.title}>{title}</h3>
        {description && (
          <Markdown className={styles.description} content={description} />
        )}
      </div>
      <div className={styles.teachers}>
        {teachers?.map((teacher) => (
          <TeacherView teacher={teacher} key={teacher.name} />
        ))}
      </div>
      <div className={styles.button}>
        <Button size="sm" variant="primary" to={button.url}>
          {button.text}
        </Button>
      </div>
    </div>
  </div>
)
