import { AboutWhatWeDoFragment } from "../../../../graphql/types"
import { WhatWeDo } from "../about-types"

export const mapAboutWhatWeDoToView = (
  data: AboutWhatWeDoFragment
): WhatWeDo => {
  return {
    title: data.whatWeDoTitle || "",
    subtitle: data.whatWeDoSubtitle || "",
    mainActivities: (data.whatWeDo3Categories || []).map((activity: any) => ({
      title: activity?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      body: activity?.description?.childMarkdownRemark?.rawMarkdownBody || "",
      image: activity?.image?.fluid?.src || ""
    })),
    otherActivities: (data.whatWeDoListOfOutreaches || []).map(
      (activity: any) => ({
        title: activity?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        body: activity?.description?.childMarkdownRemark?.rawMarkdownBody || "",
        url: activity?.urlLink || undefined,
        image: activity?.image?.fluid?.src || activity?.image?.file?.url || "",
        imageAltText: activity?.image?.title || ""
      })
    )
  }
}
