import { AboutWhoAreWeFragment } from "../../../../graphql/types"
import { WhoAreWe } from "../about-types"

export const mapAboutWhoAreWeToView = (
  data: AboutWhoAreWeFragment
): WhoAreWe => {
  return {
    title: data.whoWeAreTitle || "",
    subtitle: data.whoWeAreSubtitle || "",
    body: data.whoWeAreDescription?.childMarkdownRemark?.rawMarkdownBody || "",
    buttonText: data.whoWeAreMoreButtonText || "",
    url: data.whoWeAreMoreButtonUrl || ""
  }
}
