import { Maybe } from "../../../../graphql/types"
import { Resource } from "../../../types/resource"
import { ResourceNode } from "../home-page-types"
import { typeMap } from "../home-page-constants"
import { getMapperForResource } from "./common-mappers"

export const mapSourceFeaturedItemsToView = (
  nodes: Maybe<ResourceNode>[] | null | undefined
): (Resource | null)[] | null => {
  if (!nodes) {
    return null
  }

  return nodes.map((item) => {
    const resourceMapper = getMapperForResource(item!)
    const resource = resourceMapper(item!)

    return {
      type: typeMap[item!.internal.type],
      ...resource!
    }
  })
}
