import React, { FunctionComponent } from "react"
import Image from "gatsby-image"
import { OurReach as OurReachProps } from "../../about-types"
import styles from "./our-reach.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { Link } from "@components/link/link"

export const OurReach: FunctionComponent<OurReachProps> = ({
  title,
  list,
  description
}) => (
  <div className={styles.ourReach}>
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <Markdown className={styles.description} content={description} />
      <div className={styles.regions}>
        {list.map((region, i) => {
          return (
            <Link
              to={region.url || ""}
              className={styles.region}
              key={`${region.name}-${i}`}
            >
              {region.image && (
                <Image fluid={region.image} className={styles.image} />
              )}
              <div className={styles.text}>
                <Markdown className={styles.regionName} content={region.name} />
                <Markdown className={styles.regionBody} content={region.body} />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  </div>
)
