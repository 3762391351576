import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"
import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  AboutDonateFragment,
  AboutFounderFragment,
  AboutOurReachFragment,
  AboutPageQuery,
  AboutPartnersFragment,
  AboutStoriesFragment,
  AboutWhatWeBelieveFragment,
  AboutWhatWeDoFragment,
  AboutWhoAreWeFragment
} from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { AboutLigoniers } from "@features/about/about-types"
import { mapAboutStoriesToView } from "@features/about/mappers/about-stories-mapper"
import { mapAboutWhatWeDoToView } from "@features/about/mappers/about-what-we-do-mapper"
import { mapAboutOurReachToView } from "@features/about/mappers/about-our-reach-mapper"
import { mapAboutDonateToView } from "@features/about/mappers/about-donate-mapper"
import { mapAboutWhoAreWeToView } from "@features/about/mappers/about-who-are-we-mapper"
import { mapAboutPartnersToView } from "@features/about/mappers/about-partners-mapper"
import { mapSourceFeaturedItemsToView } from "@features/home-page/mappers/featured-items-mapper"
import { mapAboutWhoWeBelieveToView } from "@features/about/mappers/about-what-we-believe-mapper"
import { mapAboutFounderToView } from "@features/about/mappers/about-founder-mapper"
import {
  defaultMetadata,
  PageOpenGraphImage,
  PathPrefix
} from "@core/constants"
import { SectionLinks } from "@features/about/components/section-links/section-links"
import { OurMission } from "@features/about/components/our-mission/our-mission"
import { AboutStories } from "@features/about/components/about-stories/about-stories"
import { WhatWeDo } from "@features/about/components/what-we-do/what-we-do"
import { OurReach } from "@features/about/components/our-reach/our-reach"
import { SupportBox } from "@features/about/components/support-box/support-box"
import { FounderDetails } from "@features/about/components/founder/founder"
import { TeachingFellowship } from "@features/about/components/teaching-fellowship/teaching-fellowship"
import { WhatWeBelieve } from "@features/about/components/what-we-believe/what-we-believe"
import { FeaturedResources } from "@components/featured-resources/featured-resources"
import styles from "@features/about/components/about-page/about-page.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { Button } from "@components/button/button"

export const AboutPageComp: FunctionComponent<PageProps<AboutPageQuery>> = ({
  data
}) => {
  const { formatMessage } = useIntl()

  if (!data?.contentfulAboutWelcome) return null

  const teachers = (data.allContentfulTeacher?.nodes || []).map(
    (teacher: any) => ({
      name: `${teacher?.shortName}`,
      image: teacher?.image?.fluid?.src || "",
      bio: teacher?.shortBio?.childMarkdownRemark?.rawMarkdownBody || "",
      url: `${PathPrefix.Teachers}/${teacher?.slug}`
    })
  )

  const details: AboutLigoniers = {
    teachingFellows: {
      title: data.contentfulAboutWelcome.teachingFellowsTitle || ``,
      description:
        data.contentfulAboutWelcome.teachingFellowsDescription
          ?.childMarkdownRemark?.rawMarkdownBody || ``,
      teachers,
      button: {
        text: data.contentfulAboutWelcome.teachingFellowsMoreButtonText || "",
        url: data.contentfulAboutWelcome.teachingFellowsMoreButtonUrl || ""
      }
    },
    stories: mapAboutStoriesToView(
      data.contentfulAboutWelcome as AboutStoriesFragment
    ),
    whatWeDo: mapAboutWhatWeDoToView(
      data.contentfulAboutWelcome as AboutWhatWeDoFragment
    ),
    ourReach: mapAboutOurReachToView(
      data.contentfulAboutWelcome as AboutOurReachFragment
    ),
    donate: mapAboutDonateToView(
      data.contentfulAboutWelcome as AboutDonateFragment
    ),
    whoAreWe: mapAboutWhoAreWeToView(
      data.contentfulAboutWelcome as AboutWhoAreWeFragment
    ),
    partners: mapAboutPartnersToView(
      data.contentfulAboutWelcome as AboutPartnersFragment
    ),
    whatWeBelieve: mapAboutWhoWeBelieveToView(
      data.contentfulAboutWelcome as AboutWhatWeBelieveFragment
    ),
    founder: mapAboutFounderToView(
      data.contentfulAboutWelcome as AboutFounderFragment
    ),
    resources: data.contentfulAboutWelcome.newToLigonierResources
      ? mapSourceFeaturedItemsToView(
          data.contentfulAboutWelcome.newToLigonierResources
        )
      : null
  }

  const { whoAreWe } = details

  return (
    <div className={styles.aboutPage}>
      <SectionLinks
        titleId="about-about-ligonier"
        sectionLinks={[
          {
            href: "#what-we-do",
            messageId: "about-what-we-do"
          },
          {
            href: "#who-we-are",
            messageId: "about-who-we-are"
          },
          {
            href: "#what-we-believe",
            messageId: "about-what-we-believe"
          },
          {
            href: "#supporting-ligonier",
            messageId: "about-supporting-ligonier"
          }
        ]}
      />
      <OurMission
        subtitle={data.contentfulAboutWelcome.headerSubtitle || ""}
        title={data.contentfulAboutWelcome.headerTitle || ""}
        image={data.contentfulAboutWelcome.headerImage as IGatsbyImageData}
      />
      <AboutStories stories={details.stories} />
      <WhatWeDo {...details.whatWeDo} />
      <OurReach {...details.ourReach} />
      <SupportBox
        title={details.donate.title}
        subtitle={details.donate.subtitle}
        button={{
          text: formatMessage({ id: "about-donate-button" }),
          url: details.donate.url
        }}
        image={details.donate.image}
        link={details.donate.link}
      />
      <div className={styles.whoAreWe} id="who-we-are">
        <div className={styles.text}>
          <div className={styles.subtitle}>{whoAreWe.subtitle}</div>
          <h3 className={styles.title}>{whoAreWe.title}</h3>
          <Markdown className={styles.description} content={whoAreWe.body} />
        </div>
        <div className={styles.button}>
          <Button size="sm" variant="primary" to={whoAreWe.url}>
            {whoAreWe.buttonText}
          </Button>
        </div>
      </div>
      <FounderDetails details={details.founder} />
      <TeachingFellowship {...details.teachingFellows} />
      <WhatWeBelieve {...details.whatWeBelieve} />
      <SupportBox {...details.partners} />
      {details.resources && (
        <FeaturedResources
          labelId="about-new-to-ligonier"
          labelCollapse
          list={details.resources}
        />
      )}
    </div>
  )
}

const AboutPageTemplate = (props: any) => {
  const { data, ...rest } = props

  return (
    <Page
      {...rest}
      metadata={{
        ...defaultMetadata,
        title: data?.contentfulAboutWelcome?.pageTitle || defaultMetadata.title,
        openGraphImage: PageOpenGraphImage.About
      }}
      pageOptions={{ breadcrumbs: PageBreadcrumbs.About }}
    >
      <AboutPageComp {...props} />
    </Page>
  )
}

export default AboutPageTemplate

export const query = graphql`
  query AboutPage {
    contentfulAboutWelcome {
      pageTitle
      headerTitle
      headerSubtitle
      headerImage {
        gatsbyImageData(width: 492, height: 492, quality: 100)
      }
      ...AboutStories
      ...AboutWhatWeDo
      ...AboutOurReach
      ...AboutDonate
      ...AboutWhoAreWe
      ...AboutTeachingFellows
      ...AboutPartners
      ...AboutResources
      ...AboutWhatWeBelieve
      ...AboutFounder
    }
    allContentfulTeacher(
      filter: {
        ligonierTeachingFellow: { eq: true }
        contentful_id: { ne: "teacher_5" }
      }
      sort: { fields: [lastName, firstName] }
    ) {
      nodes {
        lastName
        firstName
        shortName
        shortBio {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid {
            src
          }
        }
        slug
      }
    }
  }
`
