import { AboutOurReachFragment } from "../../../../graphql/types"
import { OurReach } from "../about-types"

export const mapAboutOurReachToView = (
  data: AboutOurReachFragment
): OurReach => {
  return {
    title: data.ourReachTitle || "",
    description:
      data?.ourReachDescription?.childMarkdownRemark?.rawMarkdownBody || "",
    url: data.ourReachLinkUrl || "",
    image: data.ourReachBackgroundImage?.fluid?.src || "",
    list: (data.ourReachRegions || []).map((region: any) => ({
      name: region?.title?.childMarkdownRemark?.rawMarkdownBody || "",
      body: region?.description?.childMarkdownRemark?.rawMarkdownBody || "",
      image: region?.image?.fluid,
      url: region?.urlLink || ""
    }))
  }
}
