import { FluidObject } from "gatsby-image"
import {
  ContentfulTeacher,
  FeaturedArticleFragment,
  FeaturedDevotionalFragment,
  FeaturedMediaResourceFragment,
  FeaturedPostFragment,
  FeaturedSeriesFragment,
  FeaturedSermonFragment,
  FeaturedVideoFragment,
  FeaturedCollectionFragment
} from "../../../../graphql/types"
import { Resource } from "../../../types/resource"
import { ResourceNode } from "../home-page-types"
import { mapFirstTeacher } from "../../../core/mappers/map-teachers"
import { ImagePlaceholders, PathPrefix } from "../../../core/constants"
import {
  ContentfulScriptureReference,
  getTranslateableScripture,
  mapScriptureReference
} from "../../scriptures/mappers/scripture-reference-mapper"
import { ContentfulSeriesType } from "@src-types/graphql"

export const mapArticle = (item: FeaturedArticleFragment): Resource => {
  const teacher = mapFirstTeacher(item.teachers as Partial<ContentfulTeacher>[])

  return {
    title: item.name!,
    slug: item.slug!,
    time: item.extendedCopy
      ? item.extendedCopy.reduce((acc, cur) => {
          const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

          return time + acc
        }, 0)
      : item.bodyCopy?.childMarkdownRemark?.timeToRead || 0,
    avatar: teacher.image || "",
    subtitle: teacher.name!,
    image: item.image?.fluid?.src || ImagePlaceholders.card,
    imageFluid: item.image?.fluid as FluidObject
  }
}

export const mapDevotional = (item: FeaturedDevotionalFragment): Resource => {
  const teacher =
    item.teachers &&
    mapFirstTeacher(item.teachers as Partial<ContentfulTeacher>[])

  const scripture =
    item.primaryScriptureReference && item.primaryScriptureReference.start?.book
      ? getTranslateableScripture(
          mapScriptureReference(
            item.primaryScriptureReference as ContentfulScriptureReference
          )
        )
      : ""

  return {
    title: item.name!,
    slug: item.slug!,
    time: item.bodyCopy?.childMarkdownRemark?.timeToRead,
    avatar: "",
    subtitle: teacher?.name || scripture,
    image: ImagePlaceholders.card
  }
}

export const mapMediaResource = (
  item: FeaturedMediaResourceFragment
): Resource => {
  const teacher = mapFirstTeacher(
    item?.teachers as Partial<ContentfulTeacher>[]
  )

  let seriesSlug = ""

  if (item?.series) {
    seriesSlug =
      item.series.seriesType === "Teaching Series" ||
      item.series.seriesType === "Sermon Series"
        ? `${PathPrefix.Series}/${item.series.slug}`
        : `${PathPrefix.Conferences}/${item.series.slug}`
  }

  return {
    title: item.name!,
    slug: `${seriesSlug}/${item.slug!}`,
    avatar: teacher.image || "",
    subtitle: teacher.name,
    image: item?.series?.image?.fluid?.src || ImagePlaceholders.card
  }
}

export const mapPost = (item: FeaturedPostFragment): Resource => {
  const teacher = mapFirstTeacher(item.teachers as Partial<ContentfulTeacher>[])

  return {
    title: item.name!,
    slug: item.slug!,
    time: item.extendedCopy
      ? item.extendedCopy.reduce((acc, cur) => {
          const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

          return time + acc
        }, 0)
      : item.bodyCopy?.childMarkdownRemark?.timeToRead || 0,
    avatar: teacher.image || "",
    subtitle: teacher.name!,
    image: item.image?.fluid?.src || ImagePlaceholders.card,
    imageFluid: item.image?.fluid as FluidObject
  }
}

export const mapSermon = (item: FeaturedSermonFragment): Resource => {
  const teacher =
    item.teachers && item.teachers.length
      ? mapFirstTeacher(item.teachers as Partial<ContentfulTeacher>[])
      : undefined

  return {
    title: item.name!,
    time: item.sermonTranscript?.childMarkdownRemark?.timeToRead || 0,
    slug: item.slug!,
    avatar: teacher?.image || "",
    subtitle: teacher?.name || item.subtitle || "",
    image: ImagePlaceholders.card
  }
}

export const mapSeries = (item: FeaturedSeriesFragment): Resource => {
  const teacher = mapFirstTeacher(item.teachers as Partial<ContentfulTeacher>[])

  return {
    title: item.name!,
    slug: item.slug!,
    seriesType: item.seriesType as ContentfulSeriesType,
    avatar: teacher.image || "",
    subtitle: teacher.name,
    image: item.image?.fluid?.src || ImagePlaceholders.card,
    imageFluid: item.image?.fluid as FluidObject
  }
}

export const mapCollections = (item: FeaturedCollectionFragment): Resource => {
  return {
    title: item.name || "",
    slug: item.slug || "",
    avatar: "",
    subtitle: item.subtitle,
    image: item.image?.fluid?.src || ImagePlaceholders.card
  }
}

export const mapVideo = (item: FeaturedVideoFragment): Resource => {
  if (!item.media_resource?.length) {
    return {
      title: "",
      slug: "",
      avatar: "",
      subtitle: "",
      image: ""
    }
  }

  const media = item.media_resource![0]

  const teacher = mapFirstTeacher(
    media?.teachers as Partial<ContentfulTeacher>[]
  )

  let seriesSlug = ""

  if (media?.series) {
    seriesSlug =
      media.series.seriesType === "Teaching Series" ||
      media.series.seriesType === "Sermon Series"
        ? `${PathPrefix.Series}/${media.series.slug}`
        : `${PathPrefix.Conferences}/${media.series.slug}`
  }

  return {
    title: item.name!,
    slug: `${seriesSlug}/${item.media_resource![0]?.slug!}`,
    avatar: teacher.image || "",
    subtitle: teacher.name,
    image: media?.series?.image?.fluid?.src || ImagePlaceholders.card
  }
}

export type MapperType = (item: ResourceNode) => Resource

export const resourceMap: Record<
  string,
  { mapper: MapperType; pathPrefix: string }
> = {
  ContentfulArticle: {
    mapper: mapArticle,
    pathPrefix: PathPrefix.Articles
  },
  ContentfulDevotional: {
    mapper: mapDevotional,
    pathPrefix: PathPrefix.Devotional
  },
  ContentfulMediaResource: {
    mapper: mapMediaResource,
    pathPrefix: PathPrefix.MediaResource
  },
  ContentfulPost: {
    mapper: mapPost,
    pathPrefix: PathPrefix.Post
  },
  ContentfulSermon: {
    mapper: mapSermon as MapperType,
    pathPrefix: PathPrefix.Sermon
  },
  ContentfulSeries: {
    mapper: mapSeries,
    pathPrefix: PathPrefix.Series
  },
  ContentfulVideo: {
    mapper: mapVideo,
    pathPrefix: PathPrefix.Video
  },
  ContentfulCollection: {
    mapper: mapCollections as MapperType,
    pathPrefix: PathPrefix.Collections
  }
}

export const getLinkForResource = (
  item: ResourceNode,
  slug: string
): string => {
  const pathPrefix = resourceMap[item.internal.type].pathPrefix

  return pathPrefix ? `${pathPrefix}/${slug}` : `${slug}`
}

export const getMapperForResource = (item: ResourceNode): MapperType =>
  resourceMap[item.internal.type].mapper
