export const mapYoutubeId = (url: string | null | undefined): string | null => {
  if (!url) {
    return null
  }

  // eslint-disable-next-line no-useless-escape
  const regexp = /youtu(?:.*\/v\/|.*v\=|\.be\/)([A-Za-z0-9_\-]{11})/
  const tokens = url.match(regexp)

  if (tokens && tokens.length) {
    return tokens[1]
  }

  return null
}
