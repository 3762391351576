import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "../../../../components/button/button"
import { WhatWeBelieve as WhatWeBelieveProps } from "../../about-types"
import styles from "./what-we-believe.module.scss"
import { Markdown } from "@components/markdown/markdown"

export const WhatWeBelieve: FunctionComponent<WhatWeBelieveProps> = ({
  title,
  subtitle,
  list
}) => (
  <div className={styles.whatWeBelieve} id="what-we-believe">
    <div className={styles.content}>
      <div className={styles.text}>
        <div className={styles.subtitle}>{subtitle}</div>
        <Markdown className={styles.description} content={title} />
      </div>
      <div className={styles.list}>
        {list.map((item, i) => (
          <div className={styles.listItem} key={`${item.title}-${i}`}>
            <Markdown className={styles.itemTitle} content={item.title} />
            <Markdown className={styles.itemBody} content={item.body} />
            <Button size="sm" variant="secondary" to={item.url}>
              <FormattedMessage id="about-read-more" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  </div>
)
