import React, { FunctionComponent } from "react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import styles from "./our-mission.module.scss"

export interface OurMissionProps {
  title: string
  subtitle: string
  image: IGatsbyImageData
}

export const OurMission: FunctionComponent<OurMissionProps> = ({
  title,
  subtitle,
  image
}) => {
  const ourMissionImage = getImage(image)

  return (
    <div className={styles.ourMission}>
      <div className={styles.image}>
        {ourMissionImage && (
          <GatsbyImage image={ourMissionImage} alt="@TODO" loading="eager" />
        )}
      </div>
      <div className={styles.text}>
        <h1 className={styles.subtitle}>{subtitle}</h1>
        <h2 className={styles.title}>{title}</h2>
      </div>
    </div>
  )
}
